import { Breadcrumb, Button, DatePicker, Radio } from "antd"
import { Link } from "gatsby"
import React, { useState, useRef, useCallback, useEffect } from "react"
import "../../assets/styles/salesManagement/index.scss"
import Chart1 from "./chart/Chart1"
import Chart2 from "./chart/Chart2"
import Chart3 from "./chart/Chart3"
import Chart4 from "./chart/Chart4"
import csvep from "./chart/list.png"
import "./chart/chartStyle.css"
import locale from "antd/es/date-picker/locale/ja_JP"
import { getAdminStatistic } from "../../services/overView"
import { getDate } from "../../customUtil/global"
import moment from "moment"

const ChartParent = () => {
  const [currentTab, setcurrentTab] = useState("1")
  const chart1Ref = useRef(null)
  const Chart2Ref = useRef(null)
  const chart3Ref = useRef(null)
  const chart4Ref = useRef(null)

  const [earnings, setEarnings] = useState()

  const fetch = useCallback(async () => {
    const res = await getAdminStatistic({
      type: "revenue",
      startTime: getDate(100),
      endTime: getDate(),
    })
    if (res.data) {
      const labels = res.data.data.data.map((item) =>
        moment(item.date).format("DD/MM")
      )
      const data1 = res.data.data.data.map((item) => item.count)
      const data2 = res.data.data.dataPastYear.map((item) => item.count)
      setEarnings({
        labels,
        data1,
        data2,
      })
    }
  }, [])

  useEffect(() => {
    fetch()
  }, [fetch])

  const handleScollTo = (tabNumber) => {
    setcurrentTab(tabNumber)
    switch (tabNumber) {
      case "1":
        break
      case "2":
        chart1Ref.current?.scrollIntoView({ behavior: "smooth" })
        break
      case "3":
        Chart2Ref.current?.scrollIntoView({ behavior: "smooth" })
        break
      case "4":
        chart4Ref.current?.scrollIntoView({ behavior: "smooth" })
        break
      case "5":
        chart3Ref.current?.scrollIntoView({ behavior: "smooth" })
        break
    }
  }
  const onChange = (key) => {
    console.log(key)
  }
  const onChangeDateBefor = (date, dateString) => {
    console.log(date, dateString)
  }
  const onChangeDateAffter = (date, dateString) => {
    console.log(date, dateString)
  }

  return (
    <div className="bg-[#FFFFFF]">
      <div className="flex justify-center">
        <div className="w-[100%]">
          <div>
            <Breadcrumb separator={">"}>
              <Breadcrumb.Item>
                <Link to="/home">HOME</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="">売上管理</Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div
            style={{
              fontFamily: "Noto Sans JP",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "24px",
              letterSpacing: "0.3px",
              color: "#222222",
              marginLeft: "9px",
              marginTop: "36px",
            }}
          >
            売上管理
          </div>
          <div
            style={{
              fontFamily: "Noto Sans JP",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "14px",
              letterSpacing: "0.3px",
              color: "#222222",
              marginTop: "52px",
            }}
          >
            表示期間
          </div>
          <div className="saleManagement-input">
            <DatePicker
              onChange={onChangeDateBefor}
              className="w-[181px] h-[32px]"
              locale={locale}
            />
            <span className="fontMulish px-[5px]">から</span>
            <DatePicker
              onChange={onChangeDateAffter}
              className="w-[181px] h-[32px]"
              locale={locale}
            />
            <span className="fontMulish px-[5px]">まで</span>
            <Radio.Group onChange={onChange} style={{ marginLeft: "9px" }}>
              <Radio value={1} className="fontPicker">
                日ごと表示
              </Radio>
              <Radio value={2} className="fontPicker">
                月ごと表示
              </Radio>
            </Radio.Group>
            <Button
              danger
              style={{ background: "#EC4D43", color: "white" }}
              className="w-36 h-8 ml-[20px]"
            >
              変更する
            </Button>
          </div>
          <div className="ml-[10px]">
            <span
              className={
                currentTab === "1"
                  ? "tabHeader tabHeaderHover aroundBlockBoder"
                  : "tabHeader"
              }
              onClick={() => {
                handleScollTo("1")
              }}
            >
              売上
            </span>
            <span
              className={
                currentTab === "2"
                  ? "tabHeader tabHeaderHover aroundBlockBoder"
                  : "tabHeader"
              }
              onClick={() => {
                handleScollTo("2")
              }}
            >
              受注
            </span>
            <span
              className={
                currentTab === "3"
                  ? "tabHeader tabHeaderHover aroundBlockBoder"
                  : "tabHeader"
              }
              onClick={() => {
                handleScollTo("3")
              }}
            >
              出品
            </span>
            <span
              className={
                currentTab === "4"
                  ? "tabHeader tabHeaderHover aroundBlockBoder"
                  : "tabHeader"
              }
              onClick={() => {
                handleScollTo("4")
              }}
            >
              PV
            </span>
            <span
              className={
                currentTab === "5"
                  ? "tabHeader tabHeaderHover aroundBlockBoder"
                  : "tabHeader"
              }
              onClick={() => {
                handleScollTo("5")
              }}
            >
              商品別データ
            </span>
          </div>
          <div className="w-[100%] bg-[#FAFAFA] mt-[9px] px-[20px]">
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "right",
              }}
            >
              <button
                style={{
                  width: "153px",
                  height: "30px",
                  color: "#e60020",
                  background: "#ffffff",
                  borderRadius: "5px",
                  border: "1px solid #e60020",
                  marginTop: "20px",
                }}
                className="flex items-center justify-center"
              >
                <img className="inline" src={csvep} alt="" />
                <span
                  style={{
                    fontFamily: "Noto Sans",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "11px",
                    lineHeight: "140%",
                    marginLeft: "8.64px",
                  }}
                >
                  商品CSVダウンロード
                </span>
              </button>
            </div>
            <div ref={chart1Ref}>
              <Chart1 data={earnings} />
            </div>
            <div ref={Chart2Ref}>
              <Chart2></Chart2>
            </div>
            <div ref={chart3Ref}>
              <Chart3></Chart3>
            </div>
            <div ref={chart4Ref}>
              <Chart4></Chart4>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChartParent
