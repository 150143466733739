import React, { useEffect, useRef } from "react"
import Chart from "chart.js/auto"
import "./chartStyle.css"
const Chart2 = () => {
  const inputEl = useRef()
  useEffect(() => {
    const ctx = inputEl.current.getContext("2d")
    Chart.register({
      id: "marginlegend",
      beforeInit: function (chart, options) {
        // console.log("before Init", chart.legend)
        const originalFit = chart.legend.fit

        // Override the fit function
        chart.legend.fit = function fit() {
          // Call original function and bind scope in order to use `this` correctly inside it
          originalFit.bind(chart.legend)()
          // Change the height as suggested in another answers
          // this._margins.bottom = 200
          this.height += 15
        }
      },
      afterInit(chart, args, options) {
        // console.log("after Init", chart.legend)
      },
    })
    new Chart(ctx, {
      type: "bar",
      data: {
        labels: ["4/1", "4/2", "4/3", "4/4", "4/5", "4/6", "4/7"],
        datasets: [
          {
            data: [2100, 1000, 3000, 1700, 4300, 200, 700],
            barPercentage: 0.7,
            categoryPercentage: 0.3,
            label: "出品数",
            borderColor: "#5780A6",
            backgroundColor: "#5780A6",
            fill: false,
            padding: 23,
          },
          {
            data: [3000, 900, 2500, 1000, 5000, 200, 2700],
            barPercentage: 0.7,
            categoryPercentage: 0.3,
            label: "新規出品数",
            borderColor: "#D4E3F1",
            backgroundColor: "#D4E3F1",
            fill: false,
            padding: 23,
          },
          {
            data: [1000, 1900, 500, 1000, 3000, 1200, 700],
            barPercentage: 0.7,
            categoryPercentage: 0.3,
            label: "前年同月",
            borderColor: "#EBF0AC",
            backgroundColor: "#EBF0AC",
            fill: false,
            padding: 23,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            type: "linear",
            ticks: {
              // Include a dollar sign in the ticks
              callback: function (value, index, ticks) {
                return value + "商品"
              },
              maxTicksLimit: 6,
              color: "rgba(0, 0, 0, 0.85)",
              font: {
                size: 12,
                family: "Noto Sans JP",
                weight: "400",
                style: "normal",
                lineHeight: "17px",
              },
            },
            grid: {
              drawTicks: false,
              borderDash: [4, 4], // <-- this draw dotter hiden grid area
              color: "rgba(0, 0, 0, 0.25)",
              drawBorder: false, // <-- this removes y-axis line
            },
          },
          x: {
            grid: {
              borderColor: "#000000",
              display: false,
            },
            ticks: {
              color: "rgba(0, 0, 0, 0.85)",
              font: {
                size: 12,
                family: "Noto Sans JP",
                weight: "400",
                style: "normal",
                lineHeight: "17px",
              },
            },
          },
        },
        plugins: {
          title: {
            display: false,
            text: "Custom Chart Title",
          },
          legend: {
            display: true,
            labels: {
              color: "rgba(0, 0, 0, 0.85)",
              font: {
                size: 10,
                family: "Noto Sans JP",
                weight: "400",
                style: "normal",
                lineHeight: "14px",
              },
              boxHeight: 8,
              boxWidth: 12,
            },
            align: "start",
          },
          marginlegend: true,
        },
      },
    })
  }, [])

  return (
    <div
      style={{
        height: "444px",
        width: "100%",
        backgroundColor: "#FFFFFF",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "20px auto 0px auto",
      }}
      className="aroundBlockBoder"
    >
      <div className="w-[100%] px-[50px]">
        <div>
          <span className="redHeadText">出品</span>
          <span
            style={{
              background: "#ECB427",
              fontFamily: "Noto Sans",
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "14px",
              lineheight: "21px",
              color: "#FFFFFF",
              marginLeft: "50px",
              padding: "2px 8px 2px 8px",
              position: "relative",
              top: "-4px",
            }}
          >
            合計出品数
          </span>
          <span
            style={{
              width: "110px",
              height: "20px",
              fontfamily: "Noto Sans",
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "24px",
              lineHeight: "21px",
              color: "#ECB427",
              marginLeft: "5px",
              position: "relative",
              top: "3px",
            }}
          >
            10
          </span>
          <span
            style={{
              fontFamily: "Noto Sans",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "13px",
              lineHeight: "100%",
              color: "#222222",
              marginLeft: "10px",
              position: "relative",
              top: "1px",
            }}
          >
            商品
          </span>
        </div>
        <hr
          className="hrTag"
          style={{
            width: "100%",
          }}
        ></hr>

        <div
          style={{
            height: "264px",
            width: "100%",
            backgroundColor: "#FFFFFF",
            margin: "30px auto 0px auto",
          }}
        >
          <canvas ref={inputEl} />
        </div>
      </div>
    </div>
  )
}

export default Chart2
