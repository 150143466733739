import React from "react"
import Topbar from "../../components/organisms/topbar"
import ChartParent from "../../customComponents/onTheGo/ChartParent"
import CustomLayout from "../../Layouts/Layout"
import "./style.scss"
function index() {
  return (
    <div className="fix_ant_item">
      <Topbar />
      <CustomLayout selectedPage={<ChartParent />} />
    </div>
  )
}

export default index
